import React from "react";

import { Container } from "@mui/system";
import { getColorWithMode } from "../../constants/colors";
import { fetchProductsForComparison } from "../../utils";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Paper,
  Rating,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BrandBuyButton from "./BrandBuyButton";
import { comparisonMap } from "../../constants/gearComparison";
import { externalLinkWrapper } from "../../utils/links";
import ShareIcon from "@mui/icons-material/Share";
import MuiAlert from "@mui/material/Alert";
import { useMediaQuery } from "react-responsive";

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getUrl = (url) => {
  const rawUrl = new URL(url);
  return rawUrl.hostname.split(/\./).slice(-2).join(".");
};

const getColorForComparison = (values, key, value, product) => {
  if (key === "price") {
    if (value === values.lowestPrice) return "#4BB543";
    if (value === values.highestPrice) return "#FF5349";
    return "#eed202";
  }
  if (key === "R-Value") {
    if (Number(value) === Number(values.highestRValue)) return "#4BB543";
    if (Number(value) === Number(values.lowestRValue)) return "#FF5349";
    return "#eed202";
  }
  if (key === "Weight (Standard)" || key === "Weight (Metric)") {
    if (product.weight === values.lowestWeight) return "#4BB543";
    if (product.weight === values.highestWeight) return "#FF5349";
    return "#eed202";
  }
};

const buildSpecRows = (products, gearType) => {
  if (gearType === "sleepingPad") {
    const rowsByProductId = products.reduce((acc, curr) => {
      acc[curr._id] = [];
      return acc;
    }, {});

    const lowestAndHighestValues = products.reduce(
      (acc, curr) => {
        const price = curr.lowestPrice > 0 ? curr.lowestPrice : curr.price;
        if (price < acc.lowestPrice) acc.lowestPrice = price;
        if (price > acc.highestPrice) acc.highestPrice = price;

        if (Number(curr.rValue) < acc.lowestRValue)
          acc.lowestRValue = Number(curr.rValue);
        if (Number(curr.rValue) > acc.highestRValue)
          acc.highestRValue = Number(curr.rValue);

        if (curr.weight < acc.lowestWeight) acc.lowestWeight = curr.weight;
        if (curr.weight > acc.highestWeight) acc.highestWeight = curr.weight;
        return acc;
      },
      {
        lowestPrice: Number.MAX_SAFE_INTEGER,
        highestPrice: 0,
        lowestRValue: Number.MAX_SAFE_INTEGER,
        highestRValue: 0,
        lowestWeight: Number.MAX_SAFE_INTEGER,
        highestWeight: 0,
      }
    );

    const finalRowsById = products.reduce((acc, curr, index) => {
      const price = curr.lowestPrice > 0 ? curr.lowestPrice : curr.price;
      acc[curr._id].push(
        <TableRow key={`${curr._id}-${curr.price}`}>
          <TableCell>Price</TableCell>
          <TableCell
            align="right"
            sx={{
              color: getColorForComparison(
                lowestAndHighestValues,
                "price",
                price
              ),
            }}
          >
            {`$${price.toFixed(2)}`}
          </TableCell>
        </TableRow>
      );
      comparisonMap.sleepingPad.forEach((rule) => {
        const value = (
          curr.techSpecs.find(({ label }) => {
            return label === rule.key;
          }) || {}
        ).value;
        if (rule.colorCode === "none") {
          acc[curr._id].push(
            <TableRow key={`${curr._id}-${rule.key}`}>
              <TableCell>{rule.key}</TableCell>
              <TableCell align="right">{value}</TableCell>
            </TableRow>
          );
        } else {
          acc[curr._id].push(
            <TableRow key={`${curr._id}-${rule.key}`}>
              <TableCell sx={{ width: "200px" }}>{rule.key}</TableCell>
              <TableCell
                align="right"
                sx={{
                  color: getColorForComparison(
                    lowestAndHighestValues,
                    rule.key,
                    value,
                    curr
                  ),
                }}
              >
                {value}
              </TableCell>
            </TableRow>
          );
        }
      });
      return acc;
    }, rowsByProductId);
    return finalRowsById;
  } else {
    return products.reduce((acc, curr) => {
      acc[curr._id] = curr.techSpecs.map(({ label, value }) =>
        value ? (
          <TableRow key={`${curr._id}-${label}`}>
            <TableCell>{label}</TableCell>
            <TableCell align="right">{value}</TableCell>
          </TableRow>
        ) : (
          <></>
        )
      );
      return acc;
    }, {});
  }
};

const getTitle = (gearType) => {
  if (gearType === "sleepingPad") return "Sleeping Pads";
  if (gearType === "tent") return "Tents";
  if (gearType === "insulatedJacket") return "Insulated Jackets";
  if (gearType === "sleepingBag") return "Sleeping Bags";
};

const ItemComparisonContainer = ({ darkMode, gearType, gearText }) => {
  const [productData, setProductData] = React.useState([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const productIds = params.get("products");
  const comparisonRowsById = buildSpecRows(productData, gearType);
  const [expandDescription, setExpandDescription] = React.useState(false);
  const [snackbarIsOpen, setSnackbarIsOpen] = React.useState(false);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const isSmallScreen = useMediaQuery({ minWidth: 1130 });
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const handleSnackbarClose = () => {
    setSnackbarIsOpen(!snackbarIsOpen);
  };

  const getUrlForCheapestItem = (retailers, defaultUrl) => {
    if (retailers.length === 0) return defaultUrl;
    if (retailers.length === 1) return retailers[0].buyLink || defaultUrl;
    const sortedRetailers = retailers.sort((a, b) => a.salePrice - b.salePrice);
    const urlToUse =
      sortedRetailers.find(({ salePrice }) => salePrice && salePrice > 0)
        .buyLink || defaultUrl;
    return urlToUse;
  };

  React.useEffect(() => {
    (async () => {
      if (productIds) {
        const productsForComparison = await fetchProductsForComparison(
          productIds,
          gearType
        );
        setProductData(productsForComparison);
      }
    })();
  }, [productIds, gearType]);

  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(window.location.href);
    setSnackbarIsOpen(true);
  };

  return (
    <>
      <Stack sx={{ width: "100%", height: "100%" }}>
        <Container
          sx={{
            width: "100%",
            height: "150px",
            textAlign: "center",
            backgroundColor: getColorWithMode(darkMode, "title"),
          }}
        >
          <Button
            variant="contained"
            sx={{
              marginTop: "10px",
              width: "100px",
              left: "calc(50% - 50px)",
            }}
            startIcon={<ShareIcon />}
            onClick={handleCopyToClipBoard}
          >
            Share
          </Button>
          <Typography
            variant="h4"
            sx={{
              color: "white",
              fontWeight: "700",
              fontFamily: "Helvetica",
            }}
          >
            Compare
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "white",
              fontWeight: "700",
              fontFamily: "Helvetica",
            }}
          >
            {getTitle(gearType)}
          </Typography>
        </Container>
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            marginTop: "10px",
            overflowX: "scroll",
          }}
          justifyContent={isDesktopOrLaptop ? "center" : "left"}
          direction="row"
          spacing={0}
        >
          {productData.map((product) => (
            <Stack sx={{ width: "100%", maxWidth: "480px" }}>
              <Box
                sx={{
                  margin: "0 auto",
                  marginTop: "10px",
                  textAlign: "center",
                  width: "300px",
                  overflow: "hidden",
                  backgroundColor: "white",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "300px",
                    width: "auto",
                    marginLeft: "50%",
                    transform: "translateX(-50%)",
                    marginTop: "20px",
                    objectFit: "cover",
                    "&:hover": {
                      cursor: "pointer",
                      opacity: "0.8",
                    },
                  }}
                  alt={`${product.brand} ${product.name}`}
                  src={product.imageUrl}
                  onClick={() => {
                    const linkToUse = getUrlForCheapestItem(
                      product.retailers,
                      product.url
                    );
                    externalLinkWrapper(linkToUse);
                  }}
                />
              </Box>
              <Box sx={{ textAlign: "center", minHeight: "70px" }}>
                <Typography variant="h6">
                  {`${product.formattedBrand} ${product.name}`}
                </Typography>
                {
                  <Rating
                    readOnly
                    value={product.runningStarRating || product.reviewValue}
                  />
                }
              </Box>
              <Typography textAlign="center" variant="body1">
                {Number(product.lowestPrice || product.price).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                  }
                )}
              </Typography>
              <Box>
                <Paper
                  elevation={5}
                  sx={{
                    width: "90%",
                    margin: "0 auto",
                    marginTop: "5px",
                  }}
                >
                  <Collapse collapsedSize={"50px"} in={expandDescription}>
                    <Typography sx={{ padding: "5px" }}>
                      {product.description}
                    </Typography>
                  </Collapse>
                  <Button
                    sx={{ left: "calc(50% - 100px)", width: "200px" }}
                    onClick={() => {
                      setExpandDescription(!expandDescription);
                    }}
                  >
                    Expand Description
                  </Button>
                </Paper>
              </Box>
              <Stack>
                <Container>
                  <Typography
                    variant="h6"
                    sx={{
                      height: "20px",
                      paddingLeft: "5px",
                      marginTop: "20px",
                      fontFamily: "Helvetica",
                    }}
                  >
                    Specs
                  </Typography>
                  <Divider sx={{ paddingTop: "15px" }} />
                  <TableContainer sx={{ marginTop: "15px" }} component={Paper}>
                    <Table>
                      <TableBody>
                        {product.techSpecs && product.techSpecs.length ? (
                          comparisonRowsById[product._id]
                        ) : (
                          <TableRow>
                            <TableCell align="center">
                              No Specs Available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Container>
                {product.retailers.length === 0 ? (
                  <Container sx={{ width: "100%", marginTop: "90px" }}>
                    <Paper
                      sx={{
                        textAlign: "center",
                        height: "300px",
                      }}
                      component={Stack}
                      direction="column"
                      justifyContent="center"
                    >
                      <Stack
                        alignItems="center"
                        sx={{ marginTop: "-20px" }}
                        spacing={2}
                      >
                        <Typography
                          sx={{ fontSize: "20px" }}
                        >{`We don't yet offer live pricing for this ${gearText}`}</Typography>
                        <Button
                          variant="contained"
                          sx={{ width: "300px" }}
                          onClick={() => {
                            externalLinkWrapper(product.url);
                          }}
                        >
                          View at {getUrl(product.url)}
                        </Button>
                      </Stack>
                    </Paper>
                  </Container>
                ) : (
                  <Container>
                    <Typography
                      variant="h6"
                      sx={{
                        height: "20px",
                        paddingLeft: "5px",
                        marginTop: "20px",
                        fontFamily: "Helvetica",
                      }}
                    >
                      Retailers
                    </Typography>
                    <Divider sx={{ paddingTop: "15px" }} />
                    <TableContainer
                      sx={{ marginTop: "15px" }}
                      component={Paper}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{ paddingTop: "5px", paddingBottom: "5px" }}
                            >
                              Retailer
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ paddingTop: "5px", paddingBottom: "5px" }}
                            >
                              Price
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ paddingTop: "5px", paddingBottom: "5px" }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {product.retailers.map(
                            ({ retailer, salePrice, buyLink }) => (
                              <TableRow>
                                <TableCell align="center">
                                  <BrandBuyButton
                                    retailer={retailer}
                                    url={buyLink}
                                    darkMode={darkMode}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  {salePrice === 0 || !salePrice
                                    ? "-"
                                    : Number(salePrice).toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "USD",
                                        }
                                      )}
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => {
                                      externalLinkWrapper(buyLink);
                                    }}
                                  >
                                    Buy
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Container>
                )}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Snackbar
        open={snackbarIsOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "300px", color: "white" }}
        >
          Copied to clipboard
        </Alert>
      </Snackbar>
    </>
  );
};

export default ItemComparisonContainer;
