import React from "react";

import { useDrag, useDrop } from "react-dnd";
import DataGridContainer from "./DataGridContainer";
import TextField from "@mui/material/TextField";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import WEIGHT_TYPES from "../constants/weightTypes.json";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import * as _ from "lodash";
import { Tooltip } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const TableContainer = ({
  tableData,
  tableIndex,
  handleSetTableData,
  handleDeleteTable,
  tableId,
  id,
  affiliateLinksAreOff,
  darkMode,
  moveTable,
}) => {
  const ref = React.useRef(null);

  const [showDragStyle, setShowDragStyle] = React.useState({ display: "none" });
  const [deleteTableIsOpen, setDeleteTableIsOpen] = React.useState(false);

  const [{ handlerId }, drop] = useDrop({
    accept: "CARD",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = tableIndex;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveTable(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "CARD",
    item: () => {
      return { id, index: tableIndex };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.3 : 1;

  drag(drop(ref));

  const isMobile = useMediaQuery({ maxWidth: 898 });

  return (
    <>
      <Dialog
        open={deleteTableIsOpen}
        onClose={() => {
          setDeleteTableIsOpen(false);
        }}
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
        }}
      >
        <DialogTitle>Delete this Category?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you delete this category, it will be gone forever. Are you sure
            you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<DeleteForeverIcon />}
            variant="contained"
            color="error"
            onClick={() => handleDeleteTable(tableIndex)}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              setDeleteTableIsOpen(false);
            }}
          >
            Keep Category
          </Button>
        </DialogActions>
      </Dialog>
      <Stack
        data-handler-id={handlerId}
        sx={{
          opacity: `${opacity} !important`,
        }}
        spacing={1}
      >
        <Stack
          spacing={0}
          sx={{
            marginTop: `${tableIndex === 0 ? 0 : 8}px !important`,
          }}
        >
          <Stack
            ref={ref}
            direction="row"
            alignItems="center"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setShowDragStyle({ display: "block" });
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setShowDragStyle({ display: "none" });
            }}
          >
            <IconButton
              sx={{ ...showDragStyle, fontSize: "10px", borderRadius: "3px" }}
            >
              <DragIndicatorIcon />
            </IconButton>
            <TextField
              defaultValue={tableData.title}
              key={tableData.title}
              onBlur={(e) => {
                handleSetTableData(tableIndex, "title", e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSetTableData(tableIndex, "title", e.target.value);
                }
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                maxLength: 60,
              }}
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: isMobile ? "30px" : "20px",
                  height: isMobile ? "30px" : "20px",
                },
                "& fieldset": {
                  borderColor: "#2F2F2F",
                },
                "&:hover": {
                  borderColor: "#2F2F2F",
                },
              }}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              defaultValue={tableData.subtitle}
              key={tableData.subtitle}
              onBlur={(e) => {
                handleSetTableData(tableIndex, "subtitle", e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSetTableData(tableIndex, "subtitle", e.target.value);
                }
              }}
              size="small"
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  fontSize: isMobile ? "24px" : "16px",
                  paddingLeft: "0px",
                  fontStyle: "italic",
                  color: "#909090",
                },
                "& fieldset": {
                  borderColor: "#2F2F2F",
                },
                "&:hover": {
                  borderColor: "#2F2F2F",
                },
              }}
            />
            <IconButton
              sx={{
                marginLeft: "45px",
                height: isMobile ? "40px" : "30px",
                width: isMobile ? "40px" : "30px",
                marginRight: "7px",
              }}
              onClick={() => {
                const newWeightType =
                  tableData.markedAsWeightType === WEIGHT_TYPES.WORN
                    ? WEIGHT_TYPES.BASE
                    : WEIGHT_TYPES.WORN;
                handleSetTableData(
                  tableIndex,
                  "markedAsWeightType",
                  newWeightType
                );
              }}
            >
              <Tooltip
                title={
                  tableData.markedAsWeightType === WEIGHT_TYPES.WORN
                    ? "This whole category is marked as worn"
                    : "Mark this whole category as worn"
                }
              >
                <CheckroomIcon
                  sx={{
                    color:
                      tableData.markedAsWeightType === WEIGHT_TYPES.WORN
                        ? "#00a2e4"
                        : darkMode
                        ? "white"
                        : "#AAA",
                  }}
                />
              </Tooltip>
            </IconButton>
            <IconButton
              sx={{
                height: isMobile ? "40px" : "30px",
                width: isMobile ? "40px" : "30px",
                marginRight: "7px",
              }}
              onClick={() => {
                const newWeightType =
                  tableData.markedAsWeightType === WEIGHT_TYPES.CONSUMABLE
                    ? WEIGHT_TYPES.BASE
                    : WEIGHT_TYPES.CONSUMABLE;
                handleSetTableData(
                  tableIndex,
                  "markedAsWeightType",
                  newWeightType
                );
              }}
            >
              <Tooltip
                title={
                  tableData.markedAsWeightType === WEIGHT_TYPES.CONSUMABLE
                    ? "This whole category is marked as consumable"
                    : "Mark this whole category as consumable"
                }
              >
                <RestaurantMenuIcon
                  sx={{
                    color:
                      tableData.markedAsWeightType === WEIGHT_TYPES.CONSUMABLE
                        ? "#00a2e4"
                        : darkMode
                        ? "white"
                        : "#AAA",
                  }}
                />
              </Tooltip>
            </IconButton>
            <IconButton
              sx={{
                height: isMobile ? "40px" : "30px",
                width: isMobile ? "40px" : "30px",
              }}
              onClick={() => {
                setDeleteTableIsOpen(true);
              }}
            >
              <Tooltip title="Delete this whole category forever">
                <DeleteForeverIcon sx={{ color: "#f44030" }} />
              </Tooltip>
            </IconButton>
          </Stack>
        </Stack>
        <DataGridContainer
          defaultCategory={tableData.defaultCategory}
          tableRows={tableData.rows}
          tableIndex={tableIndex}
          handleSetTableData={handleSetTableData}
          tableWeightType={tableData.markedAsWeightType}
          packTitle={tableData.title}
          packSubtitle={tableData.subtitle}
          tableId={tableId}
          key={`${tableId}-${id}`}
          summaryRowUnit={tableData.summaryRowUnit || "oz"}
          affiliateLinksAreOff={affiliateLinksAreOff}
          darkMode={darkMode}
        />
      </Stack>
    </>
  );
};

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);

export default React.memo(TableContainer, areEqual);
