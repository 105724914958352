const fetchFilterValuesForInsulatedJackets = (rows) => {
  const brands = new Set();

  let lowestWeight = Number.MAX_SAFE_INTEGER;
  let lowestPrice = Number.MAX_SAFE_INTEGER;
  let lowestWarmth = Number.MAX_SAFE_INTEGER;
  let highestWarmth = Number.MIN_SAFE_INTEGER;

  rows.forEach((row) => {
    brands.add(row.brand);
    if (row.weight < lowestWeight) lowestWeight = row.weight;
    if (row.price < lowestPrice) lowestPrice = row.price;
    if (row.warmth && row.warmth < lowestWarmth) lowestWarmth = row.warmth;
    if (row.warmth && row.warmth > highestWarmth) highestWarmth = row.warmth;
  });
  return {
    brands: [...brands].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    ),
    weights: {
      min: Math.floor(lowestWeight),
      max: 2000,
    },
    prices: {
      min: Math.floor(lowestPrice),
      max: 1000,
    },
    warmth: { min: 1, max: 5 },
    gender: ["Mens", "Womens", "Unisex"],
    insulationType: ["Down", "Synthetic"],
    zipperStyle: ["None", "Full-Zip", "Quarter"],
    hasHood: ["Yes", "No"],
    baffle: ["Sewn", "Box"],
    rdsDown: ["Yes", "No", ""],
  };
};

export const getFilterValuesWithCategory = (gearType, rows) => {
  if (gearType === "insulatedJacket") {
    const baseFilters = fetchFilterValuesForInsulatedJackets(rows);
    const userFilters = {
      brand: baseFilters.brands,
      gender: ["Mens", "Womens", "Unisex"],
      insulationType: ["Down", "Synthetic"],
      zipperStyle: ["None", "Full-Zip", "Quarter"],
      hasHood: ["Yes", "No"],
      baffle: ["Sewn", "Box"],
      rdsDown: ["Yes", "No", ""],
    };
    return { baseFilters, userFilters };
  }
};

export const productPassesAllFilters = (
  product,
  userFilters,
  searchText = "",
  filterConfig
) => {
  let priceToUse =
    product.lowestPrice && product.lowestPrice !== 0
      ? product.lowestPrice
      : product.price;
  priceToUse = priceToUse && priceToUse > 0 ? priceToUse : null;
  product.price = priceToUse;
  const filterKeys = Object.keys(filterConfig);
  const passes = filterKeys.every((key) => {
    const filter = filterConfig[key];
    if (filter.fieldType === "multi-select") {
      return userFilters[key].includes(product[key]);
    }
    if (filter.fieldType === "slider") {
      return (
        !userFilters[key] ||
        !product[key] ||
        (product[key] >= userFilters[key][0] &&
          (product[key] <= userFilters[key][1] ||
            userFilters[key][1] === filterConfig[key].max))
      );
    }
    return true;
  });
  return (
    passes &&
    `${product.brand} ${product.name}`
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );
};
