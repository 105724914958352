const INSULATED_JACKET_FILTERS = {
  price: {
    key: "price",
    fieldType: "slider",
    max: 1000,
  },
  weight: {
    key: "weight",
    fieldType: "slider",
    max: 2000,
  },
  brand: {
    key: "brand",
    fieldType: "multi-select",
  },
  warmth: {
    label: "Warmth",
    key: "warmth",
    fieldType: "slider",
    specificToGearType: true,
    max: 5,
  },
  gender: {
    label: "Gender",
    key: "gender",
    fieldType: "multi-select",
    specificToGearType: true,
  },
  insulationType: {
    label: "Insulation Type",
    key: "insulationType",
    fieldType: "multi-select",
    specificToGearType: true,
  },
  zipperStyle: {
    label: "Zipper Style",
    key: "zipperStyle",
    fieldType: "multi-select",
    specificToGearType: true,
  },
  hasHood: {
    label: "Hooded",
    key: "hasHood",
    fieldType: "multi-select",
    specificToGearType: true,
  },
  baffle: {
    label: "Baffle",
    key: "baffle",
    fieldType: "multi-select",
    specificToGearType: true,
  },
  rdsDown: {
    label: "RDS Down",
    key: "rdsDown",
    fieldType: "multi-select",
    specificToGearType: true,
  },
};

export const filterConfigMap = {
  insulatedJacket: INSULATED_JACKET_FILTERS,
};
