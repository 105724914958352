import * as React from "react";

import {
  duplicatePackWithId,
  getUserIsLoggedIn,
  updatePackToImperial,
  updatePackToMetric,
} from "../utils";

import DeleteForever from "@mui/icons-material/DeleteForever";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ChecklistIcon from "@mui/icons-material/Checklist";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ScaleIcon from "@mui/icons-material/Scale";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { useMediaQuery } from "react-responsive";

const KebabMenu = ({
  handleDeletePack,
  isSharedPack,
  tableId,
  setMainAlertDetails,
  setMainAlertIsOpen,
  handleSelectNewPack,
  handleViewAsChecklist,
  setShareSharedPackIsOpen,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deletePackIsOpen, setDeletePackIsOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteSelected = () => {
    handleDeletePack();
    setDeletePackIsOpen(false);
  };

  const handleConvertPackToMetric = async () => {
    const packWasUpdated = await updatePackToMetric(tableId);
    if (packWasUpdated && packWasUpdated.success) {
      setMainAlertDetails({
        type: "success",
        message: "Updated Pack to Metric",
      });
      setMainAlertIsOpen(true);
      if (getUserIsLoggedIn()) {
        handleSelectNewPack(tableId);
      } else {
        window.location = `${window.location.origin}/packs`;
      }
    } else {
      setMainAlertDetails({
        type: "error",
        message: "Failed to update pack to metric",
      });
      setMainAlertIsOpen(true);
    }
  };

  const handleConvertPackToImperial = async () => {
    const packWasUpdated = await updatePackToImperial(tableId);
    if (packWasUpdated && packWasUpdated.success) {
      setMainAlertDetails({
        type: "success",
        message: "Updated Pack to Imperial",
      });
      setMainAlertIsOpen(true);
      if (getUserIsLoggedIn()) {
        handleSelectNewPack(tableId);
      } else {
        window.location = `${window.location.origin}/packs`;
      }
    } else {
      setMainAlertDetails({
        type: "error",
        message: "Failed to update pack to Imperial",
      });
      setMainAlertIsOpen(true);
    }
  };

  const handleDuplicatePack = async () => {
    const duplicatedPack = await duplicatePackWithId(tableId);
    if (!duplicatedPack.message) {
      setMainAlertDetails({
        type: "success",
        message: "Duplicated Pack",
      });
      setMainAlertIsOpen(true);
      handleSelectNewPack(duplicatedPack.tableId);
    } else {
      setMainAlertDetails({
        type: "error",
        message: "Failed to duplicate Pack",
      });
      setMainAlertIsOpen(true);
    }
    handleClose();
  };

  return (
    <>
      <Dialog
        open={deletePackIsOpen}
        onClose={() => {
          setDeletePackIsOpen(false);
        }}
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
        }}
      >
        <DialogTitle>{"Delete this Pack?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you delete this pack, it will be gone forever. Are you sure you
            want to delete this pack?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<DeleteForever />}
            variant="contained"
            color="error"
            onClick={handleDeleteSelected}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              setDeletePackIsOpen(false);
            }}
          >
            Keep Pack
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          width: "48px",
          height: "48px",
          marginLeft: isMobile ? "10px" : "5px",
        }}
      >
        <MoreVertIcon
          sx={{
            width: isMobile ? "42px" : "30px",
            height: isMobile ? "42px" : "30px",
          }}
        />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "button",
        }}
        sx={{ minWidth: "250px" }}
      >
        {!isSharedPack && (
          <MenuItem
            onClick={() => {
              setDeletePackIsOpen(true);
              handleClose();
            }}
            disabled={!getUserIsLoggedIn()}
          >
            <ListItemIcon>
              <DeleteForever fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText>Delete Pack</ListItemText>
          </MenuItem>
        )}
        {isSharedPack && (
          <MenuItem onClick={setShareSharedPackIsOpen}>
            <ListItemIcon>
              <ShareIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Share</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleDuplicatePack} disabled={!getUserIsLoggedIn()}>
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Copy to "My Packs"</ListItemText>
        </MenuItem>
        {!isSharedPack && (
          <MenuItem onClick={handleViewAsChecklist}>
            <ListItemIcon>
              <ChecklistIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>View as Checklist</ListItemText>
          </MenuItem>
        )}
        {!isSharedPack && (
          <MenuItem onClick={handleConvertPackToMetric}>
            <ListItemIcon>
              <ScaleIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Convert Pack to Metric</ListItemText>
          </MenuItem>
        )}
        {!isSharedPack && (
          <MenuItem onClick={handleConvertPackToImperial}>
            <ListItemIcon>
              <ScaleIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Convert Pack to Imperial</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default KebabMenu;
