import React from "react";
import { Container } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  getWeightInGrams,
  getWeightInUnitFromGrams,
  getWeightPercentage,
} from "../utils";
import { Box, Tooltip, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const GridVisualization = ({
  packDataArray,
  colorArray,
  totalWeightInGrams,
  isItem,
  unit,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  return (
    <Container
      sx={{
        marginLeft: "-10px",
        height: isMobile ? "235px" : "220px",
        width: isMobile ? "340px" : "240px",
        marginTop: "10px",
      }}
    >
      <Container
        sx={{
          marginLeft: "-25px",
          height: isMobile ? "235px" : "140px",
          width: isMobile ? "385px" : "255px",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <TableContainer
          variant="standard"
          size="small"
          sx={{
            borderBottom: "none",
            fontSize: isMobile ? "20px" : "10px",
            width: isMobile ? "360px" : "225px",
            marginTop: "10px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ padding: "0px 0px 0px 0px" }}>
                <TableCell sx={{ padding: "0px 0px 0px 0px", width: "15px" }} />
                <TableCell
                  sx={{
                    padding: "0px 0px 0px 0px",
                    fontSize: isMobile ? "18px" : "13px",
                  }}
                >
                  {isItem ? "Item" : "Category"}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px 0px 0px",
                    fontSize: isMobile ? "18px" : "13px",
                  }}
                  align="right"
                >
                  Weight
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0px 0px 0px 0px",
                    fontSize: isMobile ? "18px" : "13px",
                  }}
                  align="right"
                >
                  %
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {packDataArray.map((row, index) => (
                <TableRow
                  key={`row-${index}`}
                  sx={{ padding: "0px 0px 0px 0px", height: "25px" }}
                >
                  <TableCell sx={{ padding: "0px 0px 0px 0px" }}>
                    <Box
                      sx={{
                        padding: "0px 0px 0px 0px",
                        width: "10px",
                        height: "10px",
                        backgroundColor: colorArray[index],
                      }}
                    ></Box>
                  </TableCell>
                  <TableCell sx={{ padding: "0px 0px 0px 0px" }}>
                    <Tooltip title={row.item}>
                      <Typography
                        sx={{
                          maxWidth: "115px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                      >
                        {row.item}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ padding: "0px 4px 0px 0px" }} align="right">
                    <Typography sx={{ fontSize: isMobile ? "20px" : "13px" }}>
                      {`${Number(
                        getWeightInUnitFromGrams(
                          row.quantity * getWeightInGrams(row.weight, row.unit),
                          unit
                        )
                      ).toFixed(2)} ${unit}`}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "0px 0px 0px 0px" }} align="right">
                    <Typography sx={{ fontSize: isMobile ? "20px" : "13px" }}>
                      {getWeightPercentage(
                        row.quantity * getWeightInGrams(row.weight, row.unit),
                        totalWeightInGrams,
                        1
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Container>
  );
};

export default GridVisualization;
