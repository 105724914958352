import GEAR_TYPES from "../../constants/gearTypes.json";

const FILTER_TYPES = {
  WEIGHT: "WEIGHT",
  PRICE: "PRICE",
};

export const FILTER_VALUE_TYPES = {
  NUMBER: "NUMBER",
};

export const COMPARATOR_TYPES = {
  LESS_THAN: "LESS_THAN",
  GREATER_THAN: "GREATER_THAN",
  EQUAL_TO: "EQUAL_TO",
};

const ALL_FILTERS = [
  {
    label: "weight",
    type: FILTER_TYPES.WEIGHT,
    valueType: FILTER_VALUE_TYPES.NUMBER,
    comparator: COMPARATOR_TYPES.LESS_THAN,
    value: null,
  },
  {
    label: "price",
    type: FILTER_TYPES.PRICE,
    valueType: FILTER_VALUE_TYPES.NUMBER,
    comparator: COMPARATOR_TYPES.LESS_THAN,
    value: null,
  },
];

const filterGearMap = {
  [GEAR_TYPES.SLEEPING_PAD]: [...ALL_FILTERS],
  [GEAR_TYPES.SLEEPING_BAG]: [...ALL_FILTERS],
  [GEAR_TYPES.TENT]: [...ALL_FILTERS],
  [GEAR_TYPES.BACKPACK]: [...ALL_FILTERS],
  [GEAR_TYPES.INSULATED_JACKET]: [...ALL_FILTERS],
};

export default filterGearMap;
