import {
  getDefaultPack,
  updatePack,
  getPackList,
  createNewPack,
  getPackWithId,
  deletePackWithId,
  setUserDefaultPack,
  importFromLighterPack,
  getSharedPackWithId,
  generateShareableLink,
  importFromLighterPackWhileSignedOut,
  duplicatePackWithId,
  updatePackToMetric,
  updatePackToImperial,
  updatePackUnit,
  getPackDisplayData,
  getUserPackDataForAddToPackDialog,
  addItemToPackFromGearExplorer,
  updateUsersPackSettings,
  getPacksForPackExplorer,
  handleUserPackVote,
  importLocalPack,
} from "../api/pack";

import {
  searchItemsWithSubstring,
  addGearClosetItem,
  getGearCloset,
  updateGearClosetItem,
  deleteGearClosetItem,
  getItemSuggestions,
  addGearClosetItemWithId,
  deleteDuplicatesFromGearCloset,
  createNewBin,
  getUserBins,
  updateUserBin,
  deleteUserBin,
} from "../api/items";

import { logErrorOnServer } from "../api/logging";
import {
  submitContactUsMessage,
  submitContactUsMessageLoggedOut,
} from "../api/contact";
import { submitNewsletterEmail } from "../api/newsletter.js";

import AUTHENTICATED_ENDPOINT_KEYS from "../constants/authenticatedEndpointKeys.json";
import UNAUTHENTICATED_ENDPOINT_KEYS from "../constants/unauthenticatedEndpointKeys.json";
import { refreshUserToken } from "../auth";
import {
  getAdminData,
  getLinkManagerData,
  updateGearDatabaseItem,
  getReportsForAdminPanel,
  createItemForAutocomplete,
  createItemForGearExplore,
  updateItemDeleteState,
  bulkCreateItemsForGearExplore,
  getReviewDataForAdminPanel,
  fetchItemsForGTINAudit,
  updatePricingValues,
  updatePricingData,
  setStatusForReport,
  addRetailerToGear,
  getOptionsForGearTypeAutocomplete,
  getArticlesforSelection,
  createArticle,
  fetchArticleForEditingById,
  updateArticleEditsById,
  publishArticleById,
  unpublishArticleById,
} from "../api/admin";
import {
  getProductsForComparison,
  getGearMetrics,
  getDeals,
  getDailyDealsByGearType,
  fetchFilteredGearModuleData,
} from "../api/gear";
import {
  getUserProfile,
  updateUserProfile,
  updateUserPhoto,
  validateUsername,
  permanentlyUpdateUsername,
  getUsersPublicPacks,
  getUsersPackAnalytics,
  getLoggedInUserDetails,
} from "../api/profile";

import {
  getReviews,
  createReview,
  editReview,
  reportReview,
  markReviewAsHelpful,
} from "../api/reviews";

import { getDailyPacks, getPriceHistory } from "../api/daily";

import { fetchArticlePaths, fetchArticle } from "../api/articles";

const keyFunctionMapper = {
  [AUTHENTICATED_ENDPOINT_KEYS.GET_DEFAULT_TABLE]: getDefaultPack,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PACK]: updatePack,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_PACK_LIST]: getPackList,
  [AUTHENTICATED_ENDPOINT_KEYS.CREATE_NEW_PACK]: createNewPack,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_PACK_WITH_ID]: getPackWithId,
  [AUTHENTICATED_ENDPOINT_KEYS.DELETE_PACK_WITH_ID]: deletePackWithId,
  [AUTHENTICATED_ENDPOINT_KEYS.SET_USER_DEFAULT_PACK]: setUserDefaultPack,
  [AUTHENTICATED_ENDPOINT_KEYS.IMPORT_FROM_LIGHTERPACK]: importFromLighterPack,
  [UNAUTHENTICATED_ENDPOINT_KEYS.IMPORT_FROM_LIGHTERPACK_WHILE_SIGNED_OUT]:
    importFromLighterPackWhileSignedOut,
  [AUTHENTICATED_ENDPOINT_KEYS.SEARCH_ITEMS_WITH_SUBSTRING]:
    searchItemsWithSubstring,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_SHARED_PACK_WITH_ID]: getSharedPackWithId,
  [AUTHENTICATED_ENDPOINT_KEYS.GENERATE_SHAREABLE_LINK]: generateShareableLink,
  [AUTHENTICATED_ENDPOINT_KEYS.ADD_ITEM_TO_GEAR_CLOSET]: addGearClosetItem,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_GEAR_CLOSET]: getGearCloset,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_GEAR_CLOSET_ITEM]: updateGearClosetItem,
  [AUTHENTICATED_ENDPOINT_KEYS.DELETE_GEAR_CLOSET_ITEM]: deleteGearClosetItem,
  [UNAUTHENTICATED_ENDPOINT_KEYS.GET_ITEM_SUGGESTIONS]: getItemSuggestions,
  [AUTHENTICATED_ENDPOINT_KEYS.DUPLICATE_PACK_WITH_ID]: duplicatePackWithId,
  [AUTHENTICATED_ENDPOINT_KEYS.SUBMIT_CONTACT_US_MESSAGE]:
    submitContactUsMessage,
  [UNAUTHENTICATED_ENDPOINT_KEYS.SUBMIT_CONTACT_US_MESSAGE_LOGGED_OUT]:
    submitContactUsMessageLoggedOut,
  [UNAUTHENTICATED_ENDPOINT_KEYS.LOG_ERROR_ON_SERVER]: logErrorOnServer,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PACK_TO_METRIC]: updatePackToMetric,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PACK_TO_IMPERIAL]: updatePackToImperial,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PACK_UNIT]: updatePackUnit,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_ADMIN_DATA]: getAdminData,
  [UNAUTHENTICATED_ENDPOINT_KEYS.GET_PRODUCTS_FOR_COMPARISON]:
    getProductsForComparison,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_LINK_MANAGER_DATA]: getLinkManagerData,
  [UNAUTHENTICATED_ENDPOINT_KEYS.GET_DATA_FOR_PACK_DISPLAY]: getPackDisplayData,
  [UNAUTHENTICATED_ENDPOINT_KEYS.SUBMIT_NEWSLETTER_EMAIL]:
    submitNewsletterEmail,
  [AUTHENTICATED_ENDPOINT_KEYS.ADD_ITEM_TO_GEAR_CLOSET_WITH_ID]:
    addGearClosetItemWithId,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_USER_PACK_DATA_FOR_ADD_TO_PACK_DIALOG]:
    getUserPackDataForAddToPackDialog,
  [AUTHENTICATED_ENDPOINT_KEYS.ADD_ITEM_TO_PACK_FROM_GEAR_EXPLORER]:
    addItemToPackFromGearExplorer,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PACK_SETTINGS]: updateUsersPackSettings,
  [AUTHENTICATED_ENDPOINT_KEYS.FETCH_PUBLIC_PACKS_FOR_PACK_EXPLORER]:
    getPacksForPackExplorer,
  [AUTHENTICATED_ENDPOINT_KEYS.HANDLE_PACK_VOTE]: handleUserPackVote,
  [UNAUTHENTICATED_ENDPOINT_KEYS.FETCH_USER_PROFILE]: getUserProfile,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_USER_PROFILE]: updateUserProfile,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_USER_PHOTO]: updateUserPhoto,
  [UNAUTHENTICATED_ENDPOINT_KEYS.VALIDATE_USERNAME]: validateUsername,
  [AUTHENTICATED_ENDPOINT_KEYS.PERMANENTLY_UPDATE_USERNAME]:
    permanentlyUpdateUsername,
  [UNAUTHENTICATED_ENDPOINT_KEYS.GET_USERS_PUBLIC_PACKS]: getUsersPublicPacks,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_USERS_PACK_ANALYTICS]: getUsersPackAnalytics,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_GEAR_DATABASE_ITEM]:
    updateGearDatabaseItem,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_REPORTS_FOR_ADMIN_PANEL]:
    getReportsForAdminPanel,
  [AUTHENTICATED_ENDPOINT_KEYS.CREATE_ITEM_FOR_AUTOCOMPLETE]:
    createItemForAutocomplete,
  [AUTHENTICATED_ENDPOINT_KEYS.CREATE_ITEM_FOR_GEAR_EXPLORE]:
    createItemForGearExplore,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_ITEM_DELETE_STATE]: updateItemDeleteState,
  [UNAUTHENTICATED_ENDPOINT_KEYS.FETCH_GEAR_METRICS]: getGearMetrics,
  [AUTHENTICATED_ENDPOINT_KEYS.BULK_CREATE_ITEM_FOR_GEAR_EXPLORE]:
    bulkCreateItemsForGearExplore,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_LOGGED_IN_USER_DETAILS]:
    getLoggedInUserDetails,
  [AUTHENTICATED_ENDPOINT_KEYS.CREATE_REVIEW]: createReview,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_REVIEWS]: getReviews,
  [AUTHENTICATED_ENDPOINT_KEYS.EDIT_REVIEW]: editReview,
  [AUTHENTICATED_ENDPOINT_KEYS.REPORT_REVIEW]: reportReview,
  [AUTHENTICATED_ENDPOINT_KEYS.MARK_REVIEW_AS_HELPFUL]: markReviewAsHelpful,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_REVIEW_DATA_FOR_ADMIN_PANEL]:
    getReviewDataForAdminPanel,
  [AUTHENTICATED_ENDPOINT_KEYS.DELETE_DUPLICATES_FROM_GEAR_CLOSET]:
    deleteDuplicatesFromGearCloset,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_ITEMS_FOR_GTIN_AUDIT]:
    fetchItemsForGTINAudit,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PRICING_VALUES]: updatePricingValues,
  [AUTHENTICATED_ENDPOINT_KEYS.REFRESH_PRICING_DATA]: updatePricingData,
  [UNAUTHENTICATED_ENDPOINT_KEYS.GET_DAILY_PACKS]: getDailyPacks,
  [AUTHENTICATED_ENDPOINT_KEYS.CREATE_NEW_BIN]: createNewBin,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_USER_BINS]: getUserBins,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_USER_BIN]: updateUserBin,
  [AUTHENTICATED_ENDPOINT_KEYS.DELETE_USER_BIN]: deleteUserBin,
  [UNAUTHENTICATED_ENDPOINT_KEYS.GET_DEALS]: getDeals,
  [UNAUTHENTICATED_ENDPOINT_KEYS.GET_PRICE_HISTORY]: getPriceHistory,
  [AUTHENTICATED_ENDPOINT_KEYS.SET_STATUS_FOR_REPORT]: setStatusForReport,
  [UNAUTHENTICATED_ENDPOINT_KEYS.GET_DAILY_DEALS_BY_GEAR_TYPE]:
    getDailyDealsByGearType,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_ITEM_RETAILER]: addRetailerToGear,
  [AUTHENTICATED_ENDPOINT_KEYS.IMPORT_LOCAL_PACK]: importLocalPack,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_OPTIONS_FOR_GEAR_TYPE_AUTOCOMPLETE]:
    getOptionsForGearTypeAutocomplete,
  [AUTHENTICATED_ENDPOINT_KEYS.GET_ARTICLES_FOR_SELECTION]:
    getArticlesforSelection,
  [AUTHENTICATED_ENDPOINT_KEYS.CREATE_ARTICLE]: createArticle,
  [AUTHENTICATED_ENDPOINT_KEYS.FETCH_ARTICLE_FOR_EDITING_BY_ID]:
    fetchArticleForEditingById,
  [AUTHENTICATED_ENDPOINT_KEYS.UPDATE_ARTICLE_EDITS_BY_ID]:
    updateArticleEditsById,
  [AUTHENTICATED_ENDPOINT_KEYS.PUBLISH_ARTICLE]: publishArticleById,
  [UNAUTHENTICATED_ENDPOINT_KEYS.FETCH_ARTICLE_PATHS]: fetchArticlePaths,
  [UNAUTHENTICATED_ENDPOINT_KEYS.FETCH_ARTICLE_FOR_DISPLAY]: fetchArticle,
  [AUTHENTICATED_ENDPOINT_KEYS.UNPUBLISH_ARTICLE]: unpublishArticleById,
  [UNAUTHENTICATED_ENDPOINT_KEYS.FETCH_FILTERED_DATA_FOR_FILTER_MODULE]:
    fetchFilteredGearModuleData,
};

export const getAuthenticatedRequest = async (key, params) => {
  const initialResponse = await keyFunctionMapper[key](params);
  if (!initialResponse.message) return initialResponse;
  /* Hack until we find out why the review is not working with refresh token */
  if (
    initialResponse.message === "INVALID_TOKEN" ||
    key === AUTHENTICATED_ENDPOINT_KEYS.CREATE_REVIEW ||
    key === AUTHENTICATED_ENDPOINT_KEYS.EDIT_REVIEW
  ) {
    const newUser = await refreshUserToken();
    if (newUser && newUser.message) {
      await localStorage.setItem("profile", null);
      return null;
    }
    await localStorage.setItem("profile", JSON.stringify(newUser));
    const retriedRequest = await keyFunctionMapper[key](params);
    if (!retriedRequest.message) return retriedRequest;
  }
  return null;
};

export const getUnauthenticatedRequest = async (key, params) => {
  const response = await keyFunctionMapper[key](params);
  return response;
};
