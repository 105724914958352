import React from "react";

import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import { Button, Link, Stack, Typography } from "@mui/material";
import SHARED_PACKS from "../constants/SharedPacks";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { fetchDailyPacksForDisplay } from "../utils";
import SharedPackLoader from "./SharedPackLoader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const SharedPackDisplay = ({ darkMode }) => {
  const [fetchedPacks, setFetchedPacks] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const response = await fetchDailyPacksForDisplay();
      setFetchedPacks(response);
      setIsLoading(false);
    })();
  }, []);

  const currentUrl = window.location.pathname;
  return (
    <Stack gap={1} marginTop="20px" alignItems="center">
      <Typography
        sx={{
          width: "100%",
          textAlign: "center",
          color: "grey",
          paddingBottom: "10px",
        }}
      >
        Trending Packs
        <Tooltip
          title="These are public packs that are currently trending on PackWizard"
          placement="top"
        >
          <InfoOutlinedIcon
            sx={{ paddingTop: "5px", paddingLeft: "5px" }}
            fontSize="small"
          />
        </Tooltip>
      </Typography>
      <Box
        sx={{
          width: "198px",
          height: "212px",
          margin: "0 auto",
          border: "1px solid grey",
        }}
      >
        {isLoading ? (
          <SharedPackLoader />
        ) : (
          <TableContainer
            sx={{
              maxHeight: "210px",
              maxWidth: "198px",
              overflowX: "hidden",
            }}
          >
            <Table
              sx={{
                width: "198px",
                maxWidth: "198px",
              }}
              aria-label="simple table"
              size="small"
            >
              {fetchedPacks && fetchedPacks.TRENDING && (
                <TableBody>
                  {fetchedPacks.TRENDING.map(({ packTitle, packUrl }) => (
                    <TableRow key={packUrl}>
                      <Tooltip title={packTitle}>
                        <TableCell
                          key={packUrl}
                          value={packUrl}
                          aria-label="list"
                          sx={{
                            borderRadius: "0px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            overflowX: "hidden",
                            maxWidth: "198px",
                            backgroundColor:
                              packUrl === currentUrl
                                ? darkMode
                                  ? "#666"
                                  : "#F0F0F0"
                                : "",
                            "&:hover": {
                              backgroundColor:
                                packUrl === currentUrl
                                  ? ""
                                  : darkMode
                                  ? "grey"
                                  : "#F0F0F0",
                              cursor: packUrl === currentUrl ? "" : "pointer",
                            },
                          }}
                          onClick={() => {
                            window
                              .open(`${window.location.origin}${[packUrl]}`)
                              .focus();
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              textAlign: "left",
                              width: "100%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              paddingLeft: "2px",
                            }}
                          >
                            {packTitle}
                          </Typography>
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Box>
      <Typography
        sx={{
          width: "100%",
          textAlign: "center",
          color: "grey",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        Most Viewed Packs
        <Tooltip
          title="These are the most viewed public packs on PackWizard"
          placement="top"
        >
          <InfoOutlinedIcon
            sx={{ paddingTop: "5px", paddingLeft: "5px" }}
            fontSize="small"
          />
        </Tooltip>
      </Typography>
      <Box
        sx={{
          width: "198px",
          height: "212px",
          margin: "0 auto",
          border: "1px solid grey",
        }}
      >
        {isLoading ? (
          <SharedPackLoader />
        ) : (
          <TableContainer
            className="toggle-button-group-scroller"
            sx={{
              maxHeight: "210px",
              maxWidth: "198px",
              overflowX: "hidden",
            }}
          >
            <Table
              sx={{
                width: "198px",
                maxWidth: "198px",
              }}
              aria-label="simple table"
              size="small"
            >
              {fetchedPacks && fetchedPacks.MOST_VIEWED && (
                <TableBody>
                  {fetchedPacks.MOST_VIEWED.map(({ packTitle, packUrl }) => (
                    <TableRow key={packUrl}>
                      <Tooltip title={packTitle}>
                        <TableCell
                          key={packUrl}
                          value={packUrl}
                          aria-label="list"
                          sx={{
                            borderRadius: "0px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            overflowX: "hidden",
                            maxWidth: "198px",
                            backgroundColor:
                              packUrl === currentUrl
                                ? darkMode
                                  ? "#666"
                                  : "#F0F0F0"
                                : "",
                            "&:hover": {
                              backgroundColor:
                                packUrl === currentUrl
                                  ? ""
                                  : darkMode
                                  ? "grey"
                                  : "#F0F0F0",
                              cursor: packUrl === currentUrl ? "" : "pointer",
                            },
                          }}
                          onClick={() => {
                            window
                              .open(`${window.location.origin}${packUrl}`)
                              .focus();
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              textAlign: "left",
                              width: "100%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              paddingLeft: "2px",
                            }}
                          >
                            {packTitle}
                          </Typography>
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Box>
      <Link href="/browse" sx={{ textDecoration: "none" }}>
        Browse More Packs
      </Link>
    </Stack>
  );
};

export default SharedPackDisplay;
