import React from "react";

import { Route, Routes } from "react-router-dom";
import { getColorWithMode } from "../constants/colors";
import { Container } from "@mui/system";
import SleepingPadContainer from "./GearComparison/SleepingPadContainer";
import TentContainer from "./GearComparison/TentContainer";
import ItemDisplay from "./GearComparison/ItemDisplay";
import ItemComparisonContainer from "./GearComparison/ItemComparisonContainer";
import BackpackContainer from "./GearComparison/BackpackContainer";
import SleepingBagContainer from "./GearComparison/SleepingBagContainer";
import { Alert, Snackbar, Stack } from "@mui/material";
import SubNavBar from "./SubNavBar";
import { logUserInteraction } from "../api/logging";
import AddToPackDialog from "./AddToPackDialog";
import GenericGearTableContainer from "./GearComparison/GenericGearTableContainer";
import GeneralGearContainer from "./GearComparison/GeneralGearContainer";

const GearComparisonRouter = ({ darkMode }) => {
  const [addToPackSnackbarIsOpen, setAddToPackSnackbarIsOpen] =
    React.useState(false);
  const [reportSnackBarIsOpen, setReportSnackBarIsOpen] = React.useState(false);
  const [addToGCSnackbarIsOpen, setAddToGCSnackbarIsOpen] =
    React.useState(false);
  const [addToPackMenuIsOpen, setAddToPackMenuIsOpen] = React.useState(false);
  const [itemToAddToPack, setItemToAddToPack] = React.useState({});
  logUserInteraction("session", "gear");

  const handleAddToPackMenu = (id, imageUrl, brand, name, variant, type) => {
    setItemToAddToPack({ id, imageUrl, brand, name, variant, type });
    setAddToPackMenuIsOpen(true);
  };

  return (
    <Container
      sx={{
        minWidth: "360px",
        width: "100vw",
        maxWidth: "1440px",
        minHeight: "100vh",
        backgroundColor: getColorWithMode(darkMode, "base"),
        padding: "0px 0px 0px 0px",
        paddingBottom: "20px",
      }}
    >
      <Stack>
        <SubNavBar darkMode={darkMode} />
        <Routes>
          <Route
            path="/"
            element={
              <GeneralGearContainer
                darkMode={darkMode}
                setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
              />
            }
          ></Route>
          <Route
            path="/sleeping-pads"
            element={
              <SleepingPadContainer
                darkMode={darkMode}
                setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
                setReportSnackBarIsOpen={setReportSnackBarIsOpen}
                handleAddToPackMenu={handleAddToPackMenu}
                gearText="sleeping pad"
              />
            }
          ></Route>
          <Route
            path="/sleeping-pads/compare"
            element={
              <ItemComparisonContainer
                darkMode={darkMode}
                gearType="sleepingPad"
                gearText="sleeping pad"
              />
            }
          ></Route>
          <Route
            path="/sleeping-pad/:id"
            element={
              <ItemDisplay
                darkMode={darkMode}
                gearType="sleepingPad"
                gearText="sleeping pad"
              />
            }
          ></Route>
          <Route
            path="/sleeping-bags"
            element={
              <SleepingBagContainer
                darkMode={darkMode}
                setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
                setReportSnackBarIsOpen={setReportSnackBarIsOpen}
                handleAddToPackMenu={handleAddToPackMenu}
                gearText="sleeping bag"
              />
            }
          ></Route>
          <Route
            path="/sleeping-bags/compare"
            element={
              <ItemComparisonContainer
                darkMode={darkMode}
                gearType="sleepingBag"
                gearText="sleeping bag"
              />
            }
          ></Route>
          <Route
            path="/sleeping-bag/:id"
            element={
              <ItemDisplay
                darkMode={darkMode}
                gearType="sleepingBag"
                gearText="sleeping bag"
              />
            }
          ></Route>
          <Route
            path="/tents"
            element={
              <TentContainer
                darkMode={darkMode}
                setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
                setReportSnackBarIsOpen={setReportSnackBarIsOpen}
                handleAddToPackMenu={handleAddToPackMenu}
                gearText="tent"
              />
            }
          ></Route>
          <Route
            path="/tents/compare"
            element={
              <ItemComparisonContainer
                darkMode={darkMode}
                gearType="tent"
                gearText="tent"
              />
            }
          ></Route>
          <Route
            path="/tent/:id"
            element={
              <ItemDisplay
                darkMode={darkMode}
                gearType="tent"
                gearText="tent"
              />
            }
          ></Route>
          <Route
            path="/backpacks"
            element={
              <BackpackContainer
                darkMode={darkMode}
                setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
                setReportSnackBarIsOpen={setReportSnackBarIsOpen}
                handleAddToPackMenu={handleAddToPackMenu}
                gearText="pack"
              />
            }
          ></Route>
          <Route
            path="/backpacks/compare"
            element={
              <ItemComparisonContainer
                darkMode={darkMode}
                gearType="backpack"
                gearText="pack"
              />
            }
          ></Route>
          <Route
            path="/backpacks/:id"
            element={
              <ItemDisplay
                darkMode={darkMode}
                gearType="backpack"
                gearText="pack"
              />
            }
          ></Route>
          <Route
            path="/insulated-jackets"
            element={
              <GenericGearTableContainer
                gearType="insulatedJacket"
                gearText="insulated jacket"
                darkMode={darkMode}
                setAddToGCSnackbarIsOpen={setAddToGCSnackbarIsOpen}
                setReportSnackBarIsOpen={setReportSnackBarIsOpen}
                handleAddToPackMenu={handleAddToPackMenu}
              />
            }
          ></Route>
          <Route
            path="/insulated-jackets/compare"
            element={
              <ItemComparisonContainer
                darkMode={darkMode}
                gearType="insulatedJacket"
                gearText="insulated jacket"
              />
            }
          ></Route>
          <Route
            path="/insulated-jacket/:id"
            element={
              <ItemDisplay
                darkMode={darkMode}
                gearType="insulatedJacket"
                gearText="insulated jacket"
              />
            }
          ></Route>
        </Routes>
      </Stack>
      <AddToPackDialog
        item={itemToAddToPack}
        addToPackMenuIsOpen={addToPackMenuIsOpen}
        setAddToPackMenuIsOpen={setAddToPackMenuIsOpen}
        setAddToPackSnackbarIsOpen={setAddToPackSnackbarIsOpen}
      />
      <Snackbar
        open={addToPackSnackbarIsOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAddToPackSnackbarIsOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAddToPackSnackbarIsOpen(false);
          }}
          severity="success"
          sx={{ width: "300px", color: "white" }}
        >
          Added item to pack
        </Alert>
      </Snackbar>
      <Snackbar
        open={addToGCSnackbarIsOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAddToGCSnackbarIsOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAddToGCSnackbarIsOpen(false);
          }}
          severity="success"
          sx={{ width: "300px", color: "white" }}
        >
          Added to Gear Closet
        </Alert>
      </Snackbar>
      <Snackbar
        open={reportSnackBarIsOpen}
        autoHideDuration={3000}
        onClose={() => {
          setReportSnackBarIsOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setReportSnackBarIsOpen(false);
          }}
          severity="warning"
          sx={{ width: "500px", color: "white" }}
        >
          Thank you for reporting incorrect specs! We'll take a second look
          ASAP.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default GearComparisonRouter;
