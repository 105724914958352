import { Button, Container, Divider, Slider, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { getColorWithMode } from "../../constants/colors";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useMediaQuery } from "react-responsive";
import { filterConfigMap } from "../../constants/gearComparison/filterConfig";

const GenericFilterBar = ({
  gearType,
  darkMode,
  baseFilters,
  userFilters,
  setUserFilters,
  useMetric,
  setUseMetric,
  setUseMetricDimensions,
  useMetricDimensions,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });

  if (!baseFilters) return <></>;

  const handleChangeFilter = (type, value, toggled) => {
    const newFilters = { ...userFilters };
    const { key, fieldType } = filterConfigMap[gearType][type];
    if (fieldType === "slider") {
      newFilters[key] = value;
    }
    if (fieldType === "multi-select") {
      if (toggled) {
        const newSet = new Set(newFilters[key]);
        newSet.add(value);
        newFilters[key] = [...newSet];
      } else {
        const newSet = new Set(newFilters[key]);
        newSet.delete(value);
        newFilters[key] = [...newSet];
      }
    }
    setUserFilters(newFilters);
  };

  const weightMarks = [
    {
      value: baseFilters.weights.min,
      label: useMetric
        ? `${baseFilters.weights.min}g`
        : `${Math.floor(baseFilters.weights.min / 28)}oz`,
    },
    {
      value: baseFilters.weights.max,
      label: useMetric
        ? `${baseFilters.weights.max}g+`
        : `${Math.floor(baseFilters.weights.max / 28)}oz+`,
    },
  ];

  const priceMarks = [
    {
      value: baseFilters.prices.min,
      label: `$${baseFilters.prices.min}`,
    },
    {
      value: baseFilters.prices.max,
      label: `$${baseFilters.prices.max}`,
    },
  ];

  const getGenericMarks = (key) => [
    {
      value: baseFilters[key].min,
      label: baseFilters[key].min,
    },
    {
      value: baseFilters[key].max,
      label: baseFilters[key].max,
    },
  ];

  return (
    <Container
      sx={{
        backgroundColor: `${getColorWithMode("base", darkMode)} !important`,
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        direction={isMobile ? "row" : "column"}
        sx={{ width: "100%" }}
        justifyContent={"space-between"}
      >
        <Box sx={{ minWidth: "200px" }}>
          <FormControl
            sx={{ width: "100%", paddingBottom: "15px" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">Settings</FormLabel>
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useMetric}
                  onChange={() => setUseMetric(!useMetric)}
                />
              }
              label="Metric Weights"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useMetricDimensions}
                  onChange={() => setUseMetricDimensions(!useMetricDimensions)}
                />
              }
              label="Metric Dimensions"
            />
          </FormControl>
        </Box>
        <Stack>
          <Box sx={{ minWidth: "200px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Price</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.prices}
                valueLabelFormat={(value) => `$${value}`}
                defaultValue={[baseFilters.prices.min, baseFilters.prices.max]}
                marks={priceMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("price", value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%", paddingBottom: "15px" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Weight</FormLabel>
              <Divider />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  if (value === 2500) {
                    return useMetric
                      ? `${value}g+`
                      : `${Math.floor(value / 28)} oz+`;
                  }

                  return useMetric
                    ? `${value}g`
                    : `${Math.floor(value / 28)} oz`;
                }}
                sx={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "calc(100% - 20px)",
                }}
                size="small"
                {...baseFilters.weights}
                defaultValue={[
                  baseFilters.weights.min,
                  baseFilters.weights.max,
                ]}
                marks={weightMarks}
                onChangeCommitted={(e, value) => {
                  handleChangeFilter("weight", value);
                }}
              />
            </FormControl>
          </Box>
          {Object.keys(filterConfigMap[gearType])
            .filter((key) => filterConfigMap[gearType][key].specificToGearType)
            .map((filterKey) => {
              const { fieldType, label, key } =
                filterConfigMap[gearType][filterKey];
              if (fieldType === "multi-select") {
                return (
                  <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
                    <FormControl
                      sx={{ width: "100%" }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel component="legend">{label}</FormLabel>
                      <Divider />
                      <Button
                        sx={{
                          justifyContent: "left",
                          paddingLeft: "0px",
                          width: "150px",
                          "&:hover": {
                            opacity: 0.8,
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          const newFilters = { ...userFilters };
                          if (
                            userFilters[key].length !== baseFilters[key].length
                          ) {
                            newFilters[key] = baseFilters[key];
                          } else {
                            newFilters[key] = [];
                          }
                          setUserFilters(newFilters);
                        }}
                      >
                        {userFilters[key].length !== baseFilters[key].length
                          ? "Select All"
                          : "Deselect All"}
                      </Button>
                      <FormGroup>
                        {baseFilters[key].map((value) => (
                          <FormControlLabel
                            key={`${value}-${key}-filter`}
                            control={
                              <Checkbox
                                checked={userFilters[key].includes(value)}
                                name={value}
                              />
                            }
                            label={value.length ? value : "N/A"}
                            onChange={(e, newValue) => {
                              handleChangeFilter(key, value, newValue);
                            }}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Box>
                );
              }
              if (fieldType === "slider") {
                return (
                  <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
                    <FormControl
                      sx={{ width: "100%", paddingBottom: "15px" }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel component="legend">{label}</FormLabel>
                      <Divider />
                      <Slider
                        valueLabelDisplay="auto"
                        sx={{
                          marginLeft: "10px",
                          marginTop: "10px",
                          width: "calc(100% - 20px)",
                        }}
                        size="small"
                        {...baseFilters[key]}
                        defaultValue={[
                          baseFilters[key].min,
                          baseFilters[key].max,
                        ]}
                        marks={getGenericMarks(key)}
                        onChangeCommitted={(e, value) => {
                          handleChangeFilter(key, value);
                        }}
                      />
                    </FormControl>
                  </Box>
                );
              }
            })}
        </Stack>
        <Stack>
          <Box sx={{ minWidth: "200px", paddingTop: "7px" }}>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">Brand</FormLabel>
              <Divider />
              <Button
                sx={{
                  justifyContent: "left",
                  paddingLeft: "0px",
                  width: "150px",
                  "&:hover": {
                    opacity: 0.8,
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  const newFilters = { ...userFilters };
                  if (userFilters.brand.length !== baseFilters.brands.length) {
                    newFilters.brand = baseFilters.brands;
                  } else {
                    newFilters.brand = [];
                  }
                  setUserFilters(newFilters);
                }}
              >
                {userFilters.brand.length !== baseFilters.brands.length
                  ? "Select All"
                  : "Deselect All"}
              </Button>
              <FormGroup>
                {baseFilters.brands.map((brand) => (
                  <FormControlLabel
                    key={`${brand}-brand-filter`}
                    control={
                      <Checkbox
                        checked={userFilters.brand.includes(brand)}
                        name={brand}
                      />
                    }
                    label={brand}
                    onChange={(e, value) => {
                      handleChangeFilter("brand", brand, value);
                    }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default GenericFilterBar;
