export default {
  tent: ["Weight", "Sleeps", "Width", "Length", "Height", "Setup"],
  sleepingBag: [
    "Weight",
    "Shape",
    "Fits To",
    "Insulation Type",
    "Comfort Rating",
    "Limit Rating",
  ],
  sleepingPad: ["Weight", "R-Value", "Width", "Length", "Type"],
  backpack: ["Weight", "Volume", "Frame", "Max Load", "Adjustable Torso"],
  insulatedJacket: [
    "Weight",
    "Fill Power",
    "Fill Weight",
    "Warmth",
    "Warmth / Weight",
    "Insulation",
  ],
};
