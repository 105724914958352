import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import DistributionWeightGraphFilters from "./DistributionWeightGraphFilters";
import { getColorWithMode } from "../../constants/colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const productPassesFilters = (product, gearType, filters, thisProduct) => {
  if (gearType === "tent") {
    return (
      (!filters.capacity || product.capacity === thisProduct.capacity) &&
      (!filters.setup || product.setup === thisProduct.setup)
    );
  } else if (gearType === "sleepingPad") {
    return (
      (!filters.rValue ||
        (product.rValue >= thisProduct.rValue - 1 &&
          product.rValue <= thisProduct.rValue + 1)) &&
      (!filters.length ||
        (product.length >= thisProduct.length - 6 &&
          product.length <= thisProduct.length + 6)) &&
      (!filters.width ||
        (product.width >= thisProduct.width - 3 &&
          product.width <= thisProduct.width + 3)) &&
      (!filters.type || product.type === thisProduct.type)
    );
  } else if (gearType === "backpack") {
    return (
      (!filters.volume ||
        (product.volume >= thisProduct.volume - 5 &&
          product.volume <= thisProduct.volume + 5)) &&
      (!filters.framed ||
        product.framed.toLowerCase() === thisProduct.framed.toLowerCase())
    );
  } else if (gearType === "sleepingBag") {
    return (
      (!filters.comfortRating ||
        (product.comfortRating >= thisProduct.comfortRating - 5 &&
          product.comfortRating <= thisProduct.comfortRating + 5)) &&
      (!filters.fitsTo ||
        (product.fitsTo >= thisProduct.fitsTo - 3 &&
          product.fitsTo <= thisProduct.fitsTo + 3)) &&
      (!filters.type ||
        product.type.toLowerCase() === thisProduct.type.toLowerCase()) &&
      (!filters.insulationType ||
        product.insulationType.toLowerCase() === "down")
    );
  }
  return true;
};

const getFormattedWeight = (weightInGrams) => {
  if (!weightInGrams) return null;
  const weightInKg =
    weightInGrams > 2000
      ? `${(weightInGrams / 1000).toFixed(2)}kg`
      : `${weightInGrams.toFixed(0)}g`;
  const weightInLbs = Math.floor(weightInGrams / 454);
  const ounces = ((weightInGrams % 454) / 28).toFixed(1);
  return weightInLbs === 0
    ? `${ounces}oz / ${weightInKg}`
    : `${weightInLbs}lbs ${ounces}oz / ${weightInKg}`;
};

const urlMap = {
  sleepingPad: "sleeping-pad",
  tent: "tent",
  backpack: "backpacks",
  sleepingBag: "sleeping-bag",
  insulatedJacket: "insulated-jacket",
};

const NormalDistributionWeightGraph = ({
  gearType,
  productId,
  gearMetrics,
  darkMode,
}) => {
  const [data, setData] = React.useState({ labels: [], datasets: [] });
  const [filters, setFilters] = React.useState({
    capacity: true,
    rValue: true,
    volume: true,
    comfortRating: true,
  });
  const [heaviest, setHeaviest] = React.useState(null);
  const [lightest, setLightest] = React.useState(null);
  const [indexOfProduct, setIndexOfProduct] = React.useState(null);

  const toggleFilter = (filter) => {
    setFilters({ ...filters, [filter]: !filters[filter] });
  };

  const getRankingText = () => {
    if (data.datasets.length > 0 && indexOfProduct) {
      const itemCount = data.datasets[0].items.length - 1;
      const placement = itemCount - indexOfProduct;
      let placementColor = "";
      const firstQuartile = itemCount * 0.25;
      const lastQuartile = itemCount * 0.75;
      if (indexOfProduct < firstQuartile) {
        placementColor = "rgba(255, 99, 132, 0.5)";
      } else if (indexOfProduct > lastQuartile) {
        placementColor = "rgba(99, 255, 132, 0.5)";
      } else {
        placementColor = "rgba(253, 218, 13, 0.5)";
      }

      return (
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body">Rank </Typography>
          <Typography
            variant="body"
            color={placementColor}
          >{`#${placement} `}</Typography>
          <Typography variant="body">{`of ${itemCount} by weight`}</Typography>
        </Stack>
      );
    } else {
      return "";
    }
  };

  React.useEffect(() => {
    const labels = [];
    const data = [];
    const backgroundColor = [];
    const items = [];
    if (gearMetrics && gearMetrics.sortedByWeight) {
      const filteredProducts = gearMetrics.sortedByWeight.filter((product) =>
        productPassesFilters(
          product,
          gearType,
          filters,
          gearMetrics.thisProduct
        )
      );
      if (filteredProducts.length) {
        const totalSum = filteredProducts.reduce((acc, curr) => {
          acc = acc + curr.weight;
          return acc;
        }, 0);
        const mean = totalSum / filteredProducts.length;

        const greatestDistance = Math.max(
          mean - filteredProducts[0].weight,
          filteredProducts[filteredProducts.length - 1].weight - mean
        );
        let hasHitMean = false;
        let productIndex = null;
        const firstQuartile = filteredProducts.length / 4;
        const lastQuartile = filteredProducts.length * 0.75;

        filteredProducts.reverse().forEach((item, index) => {
          if (mean >= item.weight && !hasHitMean) {
            labels.push("Average");
            data.push(1.2);
            items.push({ name: "AVERAGE_ANNOTATION", weight: mean });
            backgroundColor.push("rgb(255,0,0,0.7");
            hasHitMean = true;
          }
          const distanceFromMean =
            item.weight > mean ? item.weight - mean : mean - item.weight;
          items.push(item);
          labels.push(item.name);
          data.push(1 - distanceFromMean / greatestDistance + 0.1);
          if (item._id === productId) {
            productIndex = index;
            backgroundColor.push("rgb(65, 95, 255, 0.8)");
          } else {
            if (index < firstQuartile) {
              backgroundColor.push("rgba(255, 55, 50, 0.6)");
            } else if (index > lastQuartile) {
              backgroundColor.push("rgba(99, 255, 132, 0.6)");
            } else {
              backgroundColor.push("rgba(253, 218, 13, 0.6)");
            }
          }
        });
        setLightest(filteredProducts[filteredProducts.length - 1].weight);
        setHeaviest(filteredProducts[0].weight);
        setIndexOfProduct(productIndex);
      }
    }
    setData({
      labels,
      datasets: [
        {
          data,
          backgroundColor,
          items,
        },
      ],
    });
  }, [gearMetrics, filters, gearType, productId]);

  const handleClick = (index) => {
    const itemClicked = data.datasets[0].items[index];
    window
      .open(`/gear/${urlMap[gearType]}/${itemClicked._id}`, "_blank")
      .focus();
  };

  const options = {
    responsive: true,
    onClick: (e, bar) => {
      if (bar.length > 0) {
        const clickIndex = bar[0].index;
        handleClick(clickIndex);
      }
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0]
        ? "pointer"
        : "default";
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById("chartjs-tooltip");
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = `<div style="background-color: ${getColorWithMode(
              darkMode,
              "base"
            )}; border-radius: 5px;"><table></table></div>`;
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);
            const itemIndex = context.tooltip.dataPoints[0].dataIndex;
            const item = data.datasets[0].items[itemIndex];

            let innerHtml = "<thead>";

            if (item.name === "AVERAGE_ANNOTATION") {
              titleLines.forEach(function (title) {
                innerHtml += `<tr><th align="left" style="width: 150px;">Average</th></tr>`;
              });
              innerHtml += "</thead><tbody>";
              bodyLines.forEach(function (body, i) {
                innerHtml += `<tr style="width: 150px;"><td style="font-size: 13px; border-top: 1px solid grey">${getFormattedWeight(
                  item.weight
                )}</td></tr>`;
              });
            } else {
              titleLines.forEach(function (title) {
                innerHtml += `
                    <tr>
                        <th align="left" style="width: 300px; height: 50px;">
                          <div style="display: flex;">
                              <div style="width: 50px; height: 50px; background-color: white; border-radius: 5px;">
                                <img style="
                                    position: relative;
                                    object-fit: contain;
                                    max-width: 100%;
                                    max-height: 100%;
                                    width: auto;
                                    height: auto;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                " src="${item.imageUrl}" height="50" width="50">
                              </div>
                              <div style="height: 50px;">
                                <p style="font-size: 14px; margin-top: 0px; margin-bottom: 0px; font-weight: normal; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${item.name}</p>
                                <p style="font-size: 14px; margin-top: 0px; font-weight: normal; color: grey;">${item.formattedBrand}</p>
                              </div>
                            </div>
                        </th>
                    </tr>`;
              });
              innerHtml += "</thead><tbody>";

              bodyLines.forEach(function (body, i) {
                innerHtml += `<tr style="width: 150px;"><td style="font-size: 13px; border-top: 1px solid grey">${getFormattedWeight(
                  item.weight
                )}</td></tr>`;
              });
            }
            innerHtml += "</tbody>";

            let tableRoot = tooltipEl.querySelector("table");
            tableRoot.innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.padding =
            tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
    },
    scales: {
      yAxis: {
        type: "linear",
        display: false,
      },
      x: {
        ticks: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <Paper
      sx={{
        width: "100% !important",
        maxWidth: "800px",
        maxHeight: "440px",
        padding: "5px 5px 5px 5px",
      }}
    >
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Weight Ranking</Typography>
          <Stack direction="row">{getRankingText()}</Stack>
        </Stack>
        <Divider />
        <DistributionWeightGraphFilters
          filters={filters}
          toggleFilter={toggleFilter}
          gearType={gearType}
          gearMetrics={gearMetrics}
        />
        <Bar
          style={{ width: "100%", height: "256px", maxHeight: "256px" }}
          options={options}
          data={data}
        />
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography textAlign="center" variant="subtitle2">
              Heaviest
            </Typography>
            <Typography textAlign="center" variant="subtitle2">
              {heaviest ? `${getFormattedWeight(heaviest)}` : "-"}
            </Typography>
          </Stack>
          <Stack>
            <Typography textAlign="center" variant="subtitle2">
              Lightest
            </Typography>
            <Typography textAlign="center" variant="subtitle2">
              {lightest ? `${getFormattedWeight(lightest)}` : "-"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default NormalDistributionWeightGraph;
