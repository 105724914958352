import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiRef,
  GridCellModes,
} from "@mui/x-data-grid-pro";
import * as _ from "lodash";
import { getWeightInGrams, getWeightInUnitFromGrams } from "../utils";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import WEIGHT_TYPES from "../constants/weightTypes.json";
import ShareDetailPanelContent from "./ShareDetailPanelContent";
import CustomDetailPanelToggle from "./CustomDetailPanelToggle";
import { Stack, Tooltip, Typography, Link } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { externalLinkWrapper } from "../utils/links";
import { useMediaQuery } from "react-responsive";
import { getColorWithMode } from "../constants/colors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { isValidUrl } from "../utils/validationUtils";
import BackpackIcon from "@mui/icons-material/Backpack";

const UNEDITABLE_ROW_TYPES = ["ADD_ROW", "ADDING_ROW", "DIVIDER"];

const INITIAL_SUMMARY_ROW = {
  id: "SUMMARY",
  type: "DIVIDER",
  item: "",
  weight: 0,
  unit: "oz",
  price: 0,
};

const calculateSummaryRow = (rows, summaryRowUnit) => {
  const summaryValues = Object.values(rows).reduce(
    (acc, curr) => {
      const weightInGrams = getWeightInGrams(curr.weight, curr.unit);
      const rowWeight = weightInGrams * curr.quantity;
      return {
        weight: acc.weight + rowWeight,
        price: acc.price + curr.price * curr.quantity,
      };
    },
    {
      weight: 0,
      price: 0,
    }
  );
  const finalValues = {
    weight: getWeightInUnitFromGrams(summaryValues.weight, summaryRowUnit),
    price: summaryValues.price,
    unit: summaryRowUnit,
  };
  return { ...INITIAL_SUMMARY_ROW, ...finalValues };
};

const ShareDataGridContainer = ({
  tableRows,
  handleSetTableData,
  tableIndex,
  tableId,
  summaryRowUnit,
  affiliateLinksAreOff,
  isDarkMode,
}) => {
  const isMobile = useMediaQuery({ maxWidth: "898px" });
  const summaryRow = calculateSummaryRow(tableRows, summaryRowUnit);
  const [cellModesModel, setCellModesModel] = React.useState({});
  const apiRef = useGridApiRef();

  const pinnedRows = {
    bottom: [summaryRow],
  };

  const getIconColor = (isSelected) => {
    return isSelected ? "#00a2e4" : "grey";
  };

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      if (params.field !== "__detail_panel_toggle__") {
        apiRef.current.toggleDetailPanel(params.id);
      }
      return;
    }
    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const columns = [
    {
      field: "item",
      headerName: "Item",
      width: isMobile ? 235 : 285,
      editable: false,
      flex: 1,
      headerAlign: "left",
      colSpan: ({ row }) => {
        if (row.type === "DIVIDER") {
          return 2;
        }
        if (row.type === "ADD_ROW" || row.type === "ADDING_ROW") {
          return 7;
        }
        if (isMobile) {
          return 2;
        }
      },
      sortable: false,
      headerClassName: isMobile
        ? "lastcolumnSeparator"
        : "firstcolumnSeparator",
      renderCell: (params) => (
        <Stack
          direction="row"
          alignItems="center"
          sx={{ width: "100%" }}
          justifyContent="space-between"
        >
          {isMobile ? (
            <Stack sx={{ width: "calc(100% - 25px)" }}>
              <Typography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingBottom: "0px",
                }}
              >
                {params.row.item}
              </Typography>
              <Typography
                variant={"body"}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginTop: "-5px",
                  color: getColorWithMode(isDarkMode, "lightShadow"),
                }}
              >
                {params.row.category}
              </Typography>
            </Stack>
          ) : (
            <Typography
              variant={isMobile ? "h6" : "body2"}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.row.item}
            </Typography>
          )}
          {isValidUrl(params.row.url) ? (
            <Link
              type="button"
              size="small"
              sx={{
                transform: "rotate(-45deg)",
                color: getColorWithMode(isDarkMode, "lightText"),
                "&:hover": { opacity: "0.6", cursor: "pointer" },
              }}
              onClick={(e) => {
                if (e.key !== "Enter")
                  if (isValidUrl(params.row.url)) {
                    externalLinkWrapper(params.row.url, affiliateLinksAreOff);
                  }
              }}
            >
              <LinkIcon color="primary"></LinkIcon>
            </Link>
          ) : (
            <></>
          )}
        </Stack>
      ),
    },
    {
      field: "category",
      headerName: isMobile ? "" : "Item Type",
      editable: false,
      headerAlign: "left",
      sortable: false,
      headerClassName: isMobile ? "firstcolumnSeparator" : "",
      flex: 0.5,
    },
    {
      field: "quantity",
      headerName: "Qty",
      type: "number",
      width: 60,
      editable: false,
      headerAlign: "right",
      sortable: false,
    },
    {
      field: "weight",
      headerName: "Weight",
      type: "number",
      width: 85,
      editable: false,
      headerAlign: "right",
      sortable: false,
      headerClassName: "lastcolumnSeparator",
    },
    {
      field: "unit",
      headerName: "",
      type: "singleSelect",
      width: 67,
      height: "36px",
      valueOptions: ["g", "oz", "lb", "kg"],
      editable: true,
      headerAlign: "left",
      sortable: false,
      renderCell: (params) => {
        if (params.row.id === "SUMMARY") {
          return (
            <Stack direction="row" alignItems="center">
              {params.row.unit}
              <ArrowDropDownIcon />
            </Stack>
          );
        }
        return params.row.unit;
      },
      cellClassName: "selectCell",
    },
    {
      field: "price",
      headerName: "Price",
      width: 85,
      type: "number",
      editable: false,
      headerAlign: "center",
      sortable: false,
      headerClassName: "lastcolumnSeparator",
      renderCell: (params) => {
        return Number(params.value).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      },
    },
    {
      field: "action",
      headerName: "",
      width: 20,
      headerClassName: "lastcolumnSeparator",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        if (UNEDITABLE_ROW_TYPES.includes(params.row.type)) {
          return <></>;
        }
        if (params.row.weightType === WEIGHT_TYPES.WORN) {
          return (
            <Tooltip
              title={
                tableRows[params.row.id].weightType === WEIGHT_TYPES.WORN
                  ? "This item is marked as worn"
                  : ""
              }
            >
              <CheckroomIcon
                fontSize={isMobile ? "medium" : "small"}
                sx={{
                  marginRight: "7px",
                  color: getIconColor(
                    tableRows[params.row.id].weightType === WEIGHT_TYPES.WORN
                  ),
                }}
              />
            </Tooltip>
          );
        }
        if (params.row.weightType === WEIGHT_TYPES.CONSUMABLE) {
          return (
            <Tooltip
              title={
                tableRows[params.row.id].weightType === WEIGHT_TYPES.CONSUMABLE
                  ? "This item is marked as a consumable"
                  : ""
              }
            >
              <RestaurantMenuIcon
                fontSize={isMobile ? "medium" : "small"}
                sx={{
                  color: getIconColor(
                    tableRows[params.row.id].weightType ===
                      WEIGHT_TYPES.CONSUMABLE
                  ),
                }}
              />
            </Tooltip>
          );
        }
        return (
          <>
            <Tooltip title={"This item is marked as Base Weight"}>
              <BackpackIcon
                fontSize={isMobile ? "medium" : "small"}
                sx={{
                  color: getIconColor(false),
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      headerClassName: "lastcolumnSeparator",
      headerAlign: "right",
      width: isMobile ? 65 : "",
      renderCell: (params) => {
        if (UNEDITABLE_ROW_TYPES.includes(params.row.type)) {
          return <></>;
        }
        return <CustomDetailPanelToggle id={params.id} value={params.value} />;
      },
    },
  ];

  const handleRowUpdate = (cellData) => {
    // recalculate summary row with new unit if that is the update
    const { id } = cellData;
    if (id === "SUMMARY") {
      handleSetTableData(tableIndex, "summaryRowUnit", cellData.unit);
    } else {
      // this has to have been unit
      const newRows = tableRows;
      const oldWeight = newRows[id].weight;
      const oldUnit = newRows[id].unit;

      const newWeightInGrams = getWeightInGrams(oldWeight, oldUnit);
      const newUnit = cellData.unit;
      const newWeightInUnit = getWeightInUnitFromGrams(
        newWeightInGrams,
        newUnit
      );
      newRows[id].weight = newWeightInUnit;
      newRows[id].unit = newUnit;
      handleSetTableData(tableIndex, "rows", newRows);
    }
    return cellData;
  };

  const handleRowEditError = (err) => {
    console.log(err);
  };

  const getDetailPanelHeight = React.useCallback(() => 380, []);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "calc(100vw - 35px)",
      }}
      key={`${tableId}-${tableIndex}`}
    >
      <DataGridPro
        apiRef={apiRef}
        sx={{
          borderRadius: 0,
          marginTop: "-10px",
          pr: 0,
          fontSize: isMobile ? "20px" : "14px",
        }}
        autoHeight
        rows={Object.values(tableRows)}
        pinnedRows={pinnedRows}
        columns={columns}
        disableSelectionOnClick
        hideFooter
        experimentalFeatures={{ newEditingApi: true, rowPinning: true }}
        isCellEditable={(params) => params.colDef.field === "unit"}
        getRowHeight={({ id, densityFactor }) => {
          if (id === "SUMMARY") {
            return 40 * densityFactor;
          }
          return 46 * densityFactor;
        }}
        components={{
          NoRowsOverlay: () => (
            <Box
              sx={{
                textAlign: "center",
                verticalAlign: "center",
                lineHeight: "72px",
                color: "#999",
              }}
            >
              No Items
            </Box>
          ),
        }}
        density={isMobile ? "comfortable" : "compact"}
        processRowUpdate={handleRowUpdate}
        onProcessRowUpdateError={handleRowEditError}
        disableColumnReorder
        disableColumnPinning
        getDetailPanelHeight={getDetailPanelHeight}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        getDetailPanelContent={(props) => (
          <ShareDetailPanelContent
            {...props}
            tableIndex={tableIndex}
            handleSetTableData={handleSetTableData}
            tableRows={tableRows}
            tableId={tableId}
            affiliateLinksAreOff={affiliateLinksAreOff}
            isDarkMode={isDarkMode}
          />
        )}
        disableColumnMenu
      />
    </Box>
  );
};

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);

export default React.memo(ShareDataGridContainer, areEqual);
