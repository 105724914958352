import { getLocalUser } from "../utils";

export const getAdminData = () => {
  const user = getLocalUser();
  return fetch(`/api/admin`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const getReportsForAdminPanel = () => {
  const user = getLocalUser();
  return fetch(`/api/admin/reports`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const fetchItemsForGTINAudit = () => {
  const user = getLocalUser();
  return fetch(`/api/admin/gtinaudit`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const getLinkManagerData = ({ category }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/linkManager?category=${category}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const updateGearDatabaseItem = ({ category, itemId, updateObject }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/updateItem`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
    body: JSON.stringify({ category, itemId, updateObject }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateItemDeleteState = ({ itemId, markAsDeleted }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/delete`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
    body: JSON.stringify({ itemId, markAsDeleted }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const setStatusForReport = ({ reportId, newStatus }) => {
  const user = getLocalUser();
  return fetch("/api/admin/updateItemReportStatus", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
    body: JSON.stringify({ reportId, newStatus }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const createItemForAutocomplete = ({ item }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/createItem`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ item }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const createItemForGearExplore = ({
  item,
  gearType,
  shouldAddToAutocomplete,
}) => {
  const user = getLocalUser();
  return fetch(`/api/admin/createItemForGearExplore`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ item, gearType, shouldAddToAutocomplete }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const bulkCreateItemsForGearExplore = ({ items, gearType }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/bulkCreateItemsForGearExplore`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ items, gearType }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getReviewDataForAdminPanel = () => {
  const user = getLocalUser();
  return fetch(`/api/admin/fetchReviews`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updatePricingValues = ({ itemToUpdate }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/updatePricingValues`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ itemToUpdate }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updatePricingData = ({ itemToUpdate }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/updatePricingData`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
    body: JSON.stringify({ itemToUpdate }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const addRetailerToGear = ({ itemId, category, retailerToAdd }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/addRetailerToGear`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
    body: JSON.stringify({ itemId, category, retailerToAdd }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getOptionsForGearTypeAutocomplete = ({
  gearType,
  searchQuery,
}) => {
  const user = getLocalUser();
  return fetch(
    `/api/admin/optionsForAutocomplete?gearType=${gearType}&searchQuery=${searchQuery.toLowerCase()}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getArticlesforSelection = () => {
  const user = getLocalUser();
  return fetch(`/api/admin/articlesForSelection`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchArticleForEditingById = ({ articleId }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/articleForEditingById?articleId=${articleId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateArticleEditsById = ({ articleId, edits }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/updateArticleEditsById`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
    body: JSON.stringify({ articleId, edits }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const createArticle = () => {
  const user = getLocalUser();
  return fetch(`/api/admin/createArticle`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "POST",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const publishArticleById = ({ articleId }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/publishArticle?articleId=${articleId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const unpublishArticleById = ({ articleId }) => {
  const user = getLocalUser();
  return fetch(`/api/admin/unpublishArticle?articleId=${articleId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
