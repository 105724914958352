import React from "react";

import { interpolateColors } from "../utils/colorGenerator";
import { interpolateTurbo } from "d3-scale-chromatic";
import DoughnutChart from "./DoughnutChart";
import { getWeightPercentage } from "../utils";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getWeightInUnitFromGrams } from "../utils";
import LoadingPackVisualization from "./LoadingPackVisualization";
import GridVisualization from "./GridVisualization";
import TooltipToggleButton from "./TooltipToggleButton";
import CategoryIcon from "@mui/icons-material/Category";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useMediaQuery } from "react-responsive";

const renderEmptyChartPlaceholder = (isShared, isDesktopOrLaptop, isMobile) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{
      width: isMobile ? "660px" : "400px",
      height: isMobile ? "300px" : "200px",
      fontSize: isMobile ? "20px" : "16px",
    }}
  >
    <Typography textAlign="center">
      {isShared
        ? "This pack has no items in it"
        : "Add items to your pack to begin visualizing your base weight"}
    </Typography>
  </Stack>
);

const colorScale = interpolateTurbo;
const colorRangeInfo = {
  colorStart: 0,
  colorEnd: 1,
  useEndAsStart: true,
};

const PackVisualization = ({
  labels,
  packDataArray,
  unit,
  weightsInGrams,
  isShared,
  categoryDataArray,
  categoryLabelArray,
  toggleState,
  setToggleState,
  packIsLoading,
  isDesktopOrLaptop,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const [dataArrayToUse, setDataArrayToUse] = React.useState(packDataArray);
  const [labelsToUse, setLabelsToUse] = React.useState(labels);
  const [colorArray, setColorArray] = React.useState(
    interpolateColors(dataArrayToUse.length, colorScale, colorRangeInfo)
  );
  const [showDonut, setShowDonut] = React.useState(
    localStorage.getItem("useDonutVisualization")
      ? localStorage.getItem("useDonutVisualization") === "true"
      : true
  );
  const [sliceLabelInfo, setSliceLabelInfo] = React.useState({
    label: "",
    weight: "",
  });

  React.useEffect(() => {
    setDataArrayToUse(toggleState ? packDataArray : categoryDataArray);
    setLabelsToUse(toggleState ? labels : categoryLabelArray);
    setColorArray(
      toggleState
        ? interpolateColors(packDataArray.length, colorScale, colorRangeInfo)
        : interpolateColors(
            categoryDataArray.length,
            colorScale,
            colorRangeInfo
          )
    );
    setSliceLabelInfo({
      label: "",
      weight: "",
    });
  }, [
    toggleState,
    packDataArray,
    categoryDataArray,
    labels,
    categoryLabelArray,
  ]);

  const handleChooseVisualization = () => {
    setShowDonut(!showDonut);
    setSliceLabelInfo({
      label: "",
      weight: "",
    });
    localStorage.setItem("useDonutVisualization", String(!showDonut));
  };

  const handleToggleChange = (e, values) => {
    if (values[0] === "VISUAL_TYPE") {
      setToggleState(!toggleState);
    }
    if (values[0] === "SHOW_DONUT") {
      handleChooseVisualization();
    }
  };

  return (
    <Paper
      sx={{
        width: isMobile ? "700px" : "450px",
        height: isMobile ? "400px" : "272px",
      }}
    >
      {packIsLoading ? (
        <LoadingPackVisualization />
      ) : (
        <Stack spacing={0} direction="row">
          <Stack
            sx={{
              width: isMobile ? "300px" : "200px",
            }}
          >
            <Container sx={{ paddingTop: "20px" }}>
              {dataArrayToUse.length ? (
                <DoughnutChart
                  labelState={labelsToUse}
                  packDataArray={dataArrayToUse}
                  colorArray={colorArray}
                  sliceLabelInfo={sliceLabelInfo}
                  setSliceLabelInfo={setSliceLabelInfo}
                />
              ) : (
                renderEmptyChartPlaceholder(
                  isShared,
                  isDesktopOrLaptop,
                  isMobile
                )
              )}
            </Container>
            {dataArrayToUse.length ? (
              <Box
                sx={{
                  marginLeft: "5px",
                  marginTop: "-5px",
                }}
              >
                <ToggleButtonGroup onChange={handleToggleChange}>
                  <TooltipToggleButton
                    title={`Visualize By ${toggleState ? "Category" : "Item"}`}
                    selected={!toggleState}
                    value="VISUAL_TYPE"
                    onChange={() => {
                      setToggleState(!toggleState);
                    }}
                    sx={{
                      width: isMobile ? "50px" : "35px",
                      height: isMobile ? "50px" : "35px",
                    }}
                  >
                    <CategoryIcon size={isMobile ? "large" : ""} />
                  </TooltipToggleButton>
                </ToggleButtonGroup>
              </Box>
            ) : (
              <></>
            )}
          </Stack>
          <Divider sx={{ marginTop: "5px" }} />
          {dataArrayToUse.length ? (
            <Box>
              <TableContainer
                variant="standard"
                sx={{
                  borderBottom: "none",
                  fontSize: isMobile ? "20px" : "10px",
                  width: isMobile ? "360px" : "225px",
                  marginLeft: "15px",
                  marginTop: "10px",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "18px" : "13px",
                        }}
                      >
                        Category
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "18px" : "13px",
                        }}
                        align="right"
                      >
                        Weight
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "18px" : "13px",
                        }}
                        align="right"
                      >
                        %
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                      >
                        Base
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {`${Number(
                          getWeightInUnitFromGrams(
                            weightsInGrams.baseInGrams,
                            unit
                          )
                        ).toFixed(2)} ${unit}`}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {getWeightPercentage(
                          weightsInGrams.baseInGrams,
                          weightsInGrams.weightInGrams
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                      >
                        Worn
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {`${Number(
                          getWeightInUnitFromGrams(
                            weightsInGrams.wornInGrams,
                            unit
                          )
                        ).toFixed(2)} ${unit}`}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {getWeightPercentage(
                          weightsInGrams.wornInGrams,
                          weightsInGrams.weightInGrams
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                      >
                        Consumables
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {`${Number(
                          getWeightInUnitFromGrams(
                            weightsInGrams.consumableInGrams,
                            unit
                          )
                        ).toFixed(2)} ${unit}`}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {getWeightPercentage(
                          weightsInGrams.consumableInGrams,
                          weightsInGrams.weightInGrams
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          paddingTop: "1px",
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        {`${Number(
                          getWeightInUnitFromGrams(
                            weightsInGrams.weightInGrams,
                            unit
                          )
                        ).toFixed(2)} ${unit}`}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px 0px 0px 0px",
                          fontSize: isMobile ? "20px" : "13px",
                        }}
                        align="right"
                      >
                        100.00%
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Container>
                {dataArrayToUse.length ? (
                  <GridVisualization
                    packDataArray={dataArrayToUse}
                    colorArray={colorArray}
                    totalWeightInGrams={weightsInGrams.weightInGrams}
                    isItem={toggleState}
                    isDesktopOrLaptop={isDesktopOrLaptop}
                    unit={unit}
                  />
                ) : (
                  <></>
                )}
              </Container>
            </Box>
          ) : (
            <></>
          )}
        </Stack>
      )}
    </Paper>
  );
};

export default PackVisualization;
