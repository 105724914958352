import React from "react";

import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/system/Box";
import { useMediaQuery } from "react-responsive";

const LoadingPackVisualization = ({ isDesktopOrLaptop }) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });

  const widthToUse = isDesktopOrLaptop ? 220 : isMobile ? 300 : 180;

  return (
    <Stack
      direction={isDesktopOrLaptop ? "column" : "row"}
      sx={{ height: "280px" }}
    >
      <Box>
        <Skeleton
          variant="circular"
          width={isMobile ? 250 : 200}
          height={isMobile ? 250 : 200}
          sx={{
            margin: "0 auto",
            marginTop: isMobile ? "50px" : "20px",
            ...(!isDesktopOrLaptop ? { marginLeft: "25px" } : {}),
          }}
        ></Skeleton>
      </Box>
      <Stack
        sx={{ marginLeft: isDesktopOrLaptop ? "" : isMobile ? "50px" : "25px" }}
      >
        <Box sx={{ marginTop: "70px", width: "100%" }}>
          <Skeleton
            width={widthToUse}
            height={25}
            sx={{ margin: "0 auto" }}
          ></Skeleton>
          <Skeleton
            width={widthToUse}
            height={25}
            sx={{ margin: "0 auto" }}
          ></Skeleton>
          <Skeleton
            width={widthToUse}
            height={25}
            sx={{ margin: "0 auto" }}
          ></Skeleton>
          <Skeleton
            width={widthToUse}
            height={25}
            sx={{ margin: "0 auto" }}
          ></Skeleton>
        </Box>
        {isMobile ? (
          <Box sx={{ marginTop: "70px", width: "100%" }}>
            <Skeleton
              width={widthToUse}
              height={25}
              sx={{ margin: "0 auto" }}
            ></Skeleton>
            <Skeleton
              width={widthToUse}
              height={25}
              sx={{ margin: "0 auto" }}
            ></Skeleton>
            <Skeleton
              width={widthToUse}
              height={25}
              sx={{ margin: "0 auto" }}
            ></Skeleton>
            <Skeleton
              width={widthToUse}
              height={25}
              sx={{ margin: "0 auto" }}
            ></Skeleton>
          </Box>
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  );
};

export default LoadingPackVisualization;
